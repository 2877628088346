<div
	(clickOutside)="afterClickOutside()"
	[class.epic-timepicker--visible]="isVisible"
	[ngStyle]="{ top, left }"
	class="epic-timepicker">
	<div class="epic-timepicker__title-container">
		<span>{{ text }}</span>
		<epic-button
			(click)="close()"
			size="small"
			text="Zapisz"
			type="button" />
	</div>

	<div class="epic-timepicker__content">
		<div
			#hoursContainer
			class="epic-timepicker__section">
			@for (hour of hours; track $index) {
				<button
					type="button"
					class="epic-timepicker__element"
					[class.epic-timepicker__element--active]="isActiveHour(hour)"
					[disabled]="isLessThanMinHour(hour) || isGreaterThanMaxHour(hour)"
					(click)="selectHour(hour); $event.stopPropagation()">
					{{ hour }}
				</button>
			}
		</div>
		<div
			#minutesContainer
			class="epic-timepicker__section">
			@for (minute of minutes; track $index) {
				<button
					class="epic-timepicker__element"
					[class.epic-timepicker__element--active]="isActiveMinute(minute)"
					[disabled]="isLessThanMinMinute(minute) || isGreaterThanMaxMinute(minute)"
					(click)="selectMinute(minute); $event.stopPropagation()">
					{{ minute }}
				</button>
			}
		</div>
		<!--				<div class="epic-timepicker__section">-->
		<!--					<div class="epic-timepicker__element" (click)="selectTimePeriod('AM')">AM</div>-->
		<!--					<div class="epic-timepicker__element" (click)="selectTimePeriod('PM')">PM</div>-->
		<!--				</div>-->
	</div>
</div>
