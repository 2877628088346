import { DatePipe } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { IconComponent } from '@core/components/icon/icon.component'


@Component({
	selector: 'epic-clock',
	standalone: true,
	imports: [DatePipe, IconComponent],
	template: `
		<div class="epic-clock">
			<epic-icon name="time" />
			<div class="epic-clock__container">
				{{ currentTime | date: 'HH:mm:ss' }}
			</div>
		</div>
	`,
	styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit, OnDestroy {
	currentTime: Date = new Date()
	timerID?: any

	ngOnInit() {
		this.timerID = setInterval(() => {
			this.currentTime = new Date()
		}, 1000)
	}

	ngOnDestroy() {
		if (this.timerID) {
			clearInterval(this.timerID)
		}
	}
}
