import { CommonModule } from '@angular/common'
import { Component, ElementRef, ViewChild } from '@angular/core'
import { Time } from '@core/classes/time'
import { ButtonComponent } from '@core/components/button/button.component'
import { InputComponent } from '@core/components/input/input.component'
import { ClickOutsideDirective } from '@core/directives/click-outside.directive'


@Component({
	selector: 'epic-timepicker',
	standalone: true,
	imports: [CommonModule, ButtonComponent, ClickOutsideDirective],
	templateUrl: './timepicker.component.html',
	styleUrl: './timepicker.component.scss',
})
export class TimepickerComponent {
	inputRef?: InputComponent
	isTimePeriod = false
	hours: string[] = []
	minutes: string[] = []
	text?: string

	@ViewChild('hoursContainer') hoursContainer!: ElementRef<HTMLElement>
	@ViewChild('minutesContainer') minutesContainer!: ElementRef<HTMLElement>

	private _value!: Time
	private _firstClickOutside = false
	private _selectedHour?: string
	private _selectedMinute?: string
	private _selectedTimePeriod?: string

	constructor() {
		this.hours = this._generateArray(24)
		this.minutes = this._generateArray(60)
	}

	private _isVisible = false

	/**
	 * Returns the visibility status of an element.
	 *
	 * @returns {boolean} The visibility status of the element.
	 * If true, it means the element is visible, otherwise it is not.
	 */
	get isVisible(): boolean {
		return this._isVisible
	}

	private _min?: Time

	/**
	 * Sets the minimum value for Time.
	 *
	 * @param {Time} value - The Time object to set as the minimum value.
	 */
	set min(value: Time) {
		this._min = value
	}

	private _max?: Time

	/**
	 * Sets the maximum value for a given Time object.
	 *
	 * @param {Time} value - The maximum value to be set.
	 */
	set max(value: Time) {
		this._max = value
	}

	/**
	 * Retrieves the top position of an element.
	 *
	 * @returns {string} The top position of the element in pixels.
	 */
	get top(): string {
		return this._getTopPosition() + 'px'
	}

	/**
	 * Retrieves the left position of an element.
	 *
	 * @returns {string} The left position of the element, in pixels.
	 */
	get left(): string {
		return this._getLeftPosition() + 'px'
	}

	/**
	 * Sets the selected hour and triggers the result update.
	 *
	 * @param {string} hour - The hour to set.
	 * @return {void}
	 */
	selectHour(hour: string): void {
		this._selectedHour = hour
		this._setResult()
	}

	/**
	 * Sets the selected minute.
	 *
	 * @param {string} minute - The minute to be selected.
	 * @return {void}
	 */
	selectMinute(minute: string): void {
		this._selectedMinute = minute
		this._setResult()
	}

	/**
	 * Sets the selected time period and updates the result.
	 *
	 * @param {String} timePeriod - The time period to select.
	 * @return {void}
	 */
	selectTimePeriod(timePeriod: string): void {
		this._selectedTimePeriod = timePeriod
		this._setResult()
	}

	/**
	 * Opens the time picker component.
	 * Sets isVisible flag to true.
	 * If inputRef exists and has a value, sets the selectedHour and selectedMinute based on the inputRef's value.
	 * If the inputRef's value is not an instance of Time, closes the time picker component.
	 * Scrolls to the active hour and minute elements in their respective containers, if they exist.
	 *
	 * @returns {void}
	 */
	open(): void {
		this._isVisible = true

		if (this.inputRef) {
			if (this.inputRef.control && this.inputRef.control.value) {
				if (this.inputRef.control.value instanceof Time) {
					this._value = this.inputRef.control.value
					if (this._value.hour) this._selectedHour = this._value.hourAsString()
					if (this._value.minute) this._selectedMinute = this._value.minuteAsString()
				} else this.close()
			}

			setTimeout(() => {
				const hourElement = this.hoursContainer.nativeElement.querySelector('.epic-timepicker__element--active')
				if (hourElement) {
					const hoursContainerRect = this.hoursContainer.nativeElement.getBoundingClientRect()
					const hourElementRect = hourElement.getBoundingClientRect()
					const absoluteElementTop = hourElementRect.top - hoursContainerRect.top
					this.hoursContainer.nativeElement.scrollTop =
						absoluteElementTop -
						this.hoursContainer.nativeElement.offsetHeight / 2 +
						(hourElement as any).offsetHeight / 2
				}
				const minuteElement = this.minutesContainer.nativeElement.querySelector('.epic-timepicker__element--active')
				if (minuteElement) {
					const minutesContainerRect = this.minutesContainer.nativeElement.getBoundingClientRect()
					const minuteElementRect = minuteElement.getBoundingClientRect()
					const absoluteElementTop = minuteElementRect.top - minutesContainerRect.top
					this.minutesContainer.nativeElement.scrollTop =
						absoluteElementTop -
						this.minutesContainer.nativeElement.offsetHeight / 2 +
						(minuteElement as any).offsetHeight / 2
				}
			})
		} else this.close()
	}

	/**
	 * Closes the component.
	 * Set the isVisible property to false,
	 * reset the selectedHour and selectedMinute properties to undefined,
	 * and reset the inputRef property to undefined.
	 *
	 * @return {void}
	 */
	close(): void {
		this._isVisible = false
		this._selectedHour = undefined
		this._selectedMinute = undefined
		this.inputRef = undefined
		this.hoursContainer.nativeElement.scrollTop = 0
		this.minutesContainer.nativeElement.scrollTop = 0
		this.text = undefined
		this._min = undefined
		this._max = undefined
	}

	/**
	 * Closes the element if it has been clicked outside, and sets the "_firstClickOutside" flag to true.
	 *
	 * @returns {void}
	 */
	afterClickOutside(): void {
		if (this._firstClickOutside) this.close()
		else this._firstClickOutside = true
	}

	/**
	 * Checks if the given value is the selected hour.
	 *
	 * @param {string} value - The value to be checked against the selected hour.
	 * @return {boolean} - Returns true if the value is the selected hour, otherwise returns false.
	 */
	isActiveHour(value: string): boolean {
		return this._value?.hour === parseInt(value)
	}

	/**
	 * Determines if the given value is an active minute.
	 *
	 * @param {string} value - The minute value to check.
	 * @returns {boolean} - True if the value is the selected minute, false otherwise.
	 */
	isActiveMinute(value: string): boolean {
		return this._value?.minute === parseInt(value)
	}

	/**
	 * Checks if the given value is less than the minimum hour.
	 *
	 * @param {string} value - The value to compare.
	 *
	 * @returns {boolean} - Returns true if the value is less than the minimum hour, otherwise false.
	 */
	isLessThanMinHour(value: string): boolean {
		if (!this._min || !this._min.hour) return false
		else return parseInt(value) < this._min.hour
	}

	/**
	 * Checks if the given value is greater than the maximum hour.
	 *
	 * @param {string} value - The value to check.
	 * @returns {boolean} - True if the value is greater than the maximum hour, false otherwise.
	 */
	isGreaterThanMaxHour(value: string): boolean {
		if (!this._max || !this._max.hour) return false
		else return parseInt(value) > this._max.hour
	}

	/**
	 * Checks if the given value is less than the minimum minute value.
	 *
	 * @param {string} value - The value to be checked.
	 * @return {boolean} - True if the value is less than the minimum minute value, otherwise false.
	 */
	isLessThanMinMinute(value: string): boolean {
		if (
			!this._min ||
			this._min.minute === undefined ||
			this._min.hour === undefined ||
			this._min.hour !== this._value.hour
		)
			return false
		else return parseInt(value) < this._min.minute
	}

	/**
	 * Checks if the given value is greater than the maximum minute value.
	 *
	 * @param {string} value - The value to check against the maximum minute value.
	 * @returns {boolean} - `true` if the value is greater than the maximum minute value, `false` otherwise.
	 */
	isGreaterThanMaxMinute(value: string): boolean {
		if (
			!this._max ||
			this._max.minute === undefined ||
			this._max.hour === undefined ||
			this._max.hour !== this._value.hour
		)
			return false
		else return parseInt(value) > this._max.minute
	}

	/**
	 * @private
	 * Sets the result of the selection.
	 */
	private _setResult() {
		if (this._selectedHour) this._value.hour = parseInt(this._selectedHour)

		if (
			this._value.minute !== undefined &&
			(this.isLessThanMinMinute(this._value.minuteAsString()) ||
				this.isGreaterThanMaxMinute(this._value.minuteAsString()))
		) {
			this._selectedMinute = undefined
			this._value.minute = undefined
		} else {
			if (this._selectedMinute) this._value.minute = parseInt(this._selectedMinute)
		}

		// if (this.selectedTimePeriod) {
		//   this._value.timePeriod = this.selectedTimePeriod;
		// }

		this.inputRef?.control.setValue(this._value)
	}

	/**
	 * @private
	 * Generates an array of strings.
	 *
	 * @param {number} n - The number of elements to generate in the array.
	 * @return {string[]} - An array of strings.
	 */
	private _generateArray(n: number): string[] {
		const array: string[] = []
		for (let i = 0; i < n; i++) {
			let item = i.toString()
			if (i < 10) {
				item = '0' + item
			}
			array.push(item)
		}
		return array
	}

	/**
	 * @private
	 * Returns the top position of the input element plus an offset of 10px.
	 *
	 * @return {number} The calculated top position.
	 */
	private _getTopPosition(): number {
		if (this.inputRef?.elementRef.nativeElement) {
			return (
				this.inputRef.elementRef.nativeElement.getBoundingClientRect().top +
				this.inputRef.elementRef.nativeElement.clientHeight +
				10
			)
		} else return 0
	}

	/**
	 * @private
	 * Retrieves the left position of the element.
	 *
	 * @returns {number} The left position of the element. If the element is not found, returns 0.
	 */
	private _getLeftPosition(): number {
		if (this.inputRef?.elementRef?.nativeElement)
			return this.inputRef.elementRef.nativeElement.getBoundingClientRect().left
		else return 0
	}
}
