import { Component, OnDestroy, OnInit } from '@angular/core'
import { DatePipe } from '@angular/common'

@Component({
	selector: 'epic-date',
	standalone: true,
	imports: [DatePipe],
	template: `
		<div class="epic-date">
			{{ currentDate | date: 'fullDate' }}
		</div>
	`,
	styleUrl: './date.component.scss',
})
export class DateComponent implements OnInit, OnDestroy {
	currentDate: Date = new Date()
	timerID?: any

	ngOnInit() {
		this.timerID = setInterval(() => {
			this.currentDate = new Date()
		}, 30_000)
	}

	ngOnDestroy() {
		if (this.timerID) {
			clearInterval(this.timerID)
		}
	}
}
