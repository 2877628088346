@if (userEquipmentList.items.length > 0) {
<div [class]="isModal ? '' : 'user-regulations__subsection'">
	<div *ngIf="!isModal">
		<h3
			class="font-weight__semibold"
			i18n>
			Equipment
		</h3>
	</div>
	<div class="user-regulations form__element-12 form">
		<div class="user-regulations__list form__element-12">
			<epic-table
				[generateCheckboxes]="false"
				class="contractors-page-table">
				<tr
					class="font__small"
					table-headers
					table-row-data>
					<epic-table-header class="font-align__center">
						<span i18n="@@ordinalNumber">No.</span>
					</epic-table-header>
					<epic-table-header>
						<span i18n>Name</span>
					</epic-table-header>
					<epic-table-header>
						<span i18n>Equipment transfer date</span>
					</epic-table-header>
					<epic-table-header>
						<span i18n>Serial number</span>
					</epic-table-header>
					<epic-table-header>
						<span i18n>Model</span>
					</epic-table-header>
					<epic-table-header>
						<span i18n>Manufacturer</span>
					</epic-table-header>
					<epic-table-header>
						<span i18n>Purchase date</span>
					</epic-table-header>
					<epic-table-header>
						<span i18n>Additional information</span>
					</epic-table-header>
				</tr>
				@for (item of userEquipmentList.items; track item; let index = $index) {
				<tr class="font__small">
					<td class="font-align__center">{{ index + 1 }}</td>
					<td>{{ item.equipment.name }}</td>
					<td>{{ item.equipment_transfer_date | date: 'dd.MM.YYYY' }}</td>
					<td>{{ item.equipment.serial_number }}</td>
					<td>{{ item.equipment.model }}</td>
					<td>{{ item.equipment.manufacturer }}</td>
					<td>{{ item.equipment.purchase_date | date: 'dd.MM.YYYY' }}</td>
					<td>{{ item.equipment.additional_information }}</td>
				</tr>
				}
			</epic-table>
		</div>
		<div class="form__element-12">
			<div
				*ngIf="regulationText"
				class="settings-regulations__preview"
				[innerHTML]="regulationText.renderedMDHtml"></div>
		</div>
		<div
			*ngIf="isModal"
			class="user-regulations__bottom form__element-12">
			<epic-button
				size="large"
				(click)="acceptUsePolicy()">
				<epic-icon [name]="'tick-bold'" />
				<span i18n>ACCEPT</span>
			</epic-button>
		</div>
	</div>
</div>
}
